import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import Blocks from "@shared/blocks";

interface Data extends Query {
  altRegions: SanityPageConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function IndexPage({ data, pageContext }: Props) {
  const node = data.sanityPage;

  const contextIban = pageContext.iban;
  useRegionChanged("", contextIban);
  if (!node) return null;

  const { hero, seo, components } = node;
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);

  return (
    <div>
      <SEO seoData={seo} alternateRegions={alternateSlugs} heroData={hero} />
      <Hero heroData={hero} isHomepage />
      <Blocks components={components} />
    </div>
  );
}

export const query = graphql`
  query PageQuery($iban: String, $slug: String) {
    sanityPage(
      region: { iban: { eq: $iban } }
      slug: { current: { eq: $slug } }
    ) {
      hero {
        ...sanityHero
      }
      region {
        ...sanityRegion
      }
      seo {
        ...sanitySeo
      }
      components {
        ...sanityComponents
      }
    }
    altRegions: allSanityPage {
      nodes {
        slug {
          current
        }
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
