import React from "react";

import {
  LeftRightBlock,
  FullWithImageBlock,
  CarouselWithParagraph,
  CarouselWithButton,
  ParagraphBlock,
  FeaturedBlock,
  LatestNewsBlock,
} from "@global";
import {
  isSanityLeftRightBlock,
  isSanityFullWidthImageBlock,
  isSanityCarouselWithParagraph,
  isSanityCarouselWithButton,
  isSanityBlockContent,
  isSanityFeatureBlockArray,
  isSanityLatestNewsBlock,
} from "@util/types";

interface Props {
  components: any;
}

export default function Blocks({ components }: Props) {
  if (!components) return null;

  return (
    <div>
      {components.map((data: any) => {
        if (!data) return null;
        const { _key } = data;

        if (isSanityLeftRightBlock(data)) {
          return <LeftRightBlock data={data} key={_key} />;
        }
        if (isSanityFullWidthImageBlock(data)) {
          return <FullWithImageBlock data={data} key={_key} />;
        }
        if (isSanityCarouselWithParagraph(data)) {
          return <CarouselWithParagraph data={data} key={_key} />;
        }
        if (isSanityCarouselWithButton(data)) {
          return <CarouselWithButton data={data} key={_key} />;
        }
        if (isSanityBlockContent(data)) {
          return <ParagraphBlock data={data} key={_key} />;
        }
        if (isSanityFeatureBlockArray(data)) {
          return <FeaturedBlock data={data} key={_key} />;
        }
        if (isSanityLatestNewsBlock(data)) {
          if (!data.displayToggle) return null;
          return <LatestNewsBlock data={data} key={_key} />;
        }
        return null;
      })}
    </div>
  );
}
